<template>
  <div
    ref="parent"
    class="m-str white--bg"
  >
    <div class="m-str__header d-flex align-center justify-between">
      <d-btn
        data-test="back-button"
        icon
        color="custom"
        class="modal__back"
        @click="$emit('close')"
      >
        <img
          loading="lazy"
          src="@/assets/images/svg/arrow-right.svg"
          alt="arrow"
          style="transform: rotate(180deg)"
        >
      </d-btn>
      <button
        class="m-str__city relative"
        @click="showDialogCity"
      >
        {{
          currentCity?.short_name
            ? currentCity.short_name + " "
            : ""
        }}{{ currentCity?.name }}
      </button>
    </div>
    <div class="m-str__search">
      <img
        loading="lazy"
        src="@/assets/images/svg/search-thin.svg"
        class="m-str__search-search"
        alt="search"
        style="width: 24px; height: 24px;"
      >
      <input
        type="text"
        :value="search"
        placeholder="Введите улицу"
        autocomplete="honorific-suffix"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        @focus="setRange"
        @input="inputSearch"
        @keyDown="keyDown"
      >
      <img
        v-if="search"
        class="m-str__search-clear"
        loading="lazy"
        src="~/assets/images/svg/input-clear.svg"
        alt="logo"
        @click="clearStreet"
      >
    </div>
    <div
      ref="scrollRef"
      class="m-str__letters d-flex relative"
    >
      <div
        ref="scrollWrap"
        class="d-flex"
        style="margin-bottom: 10px"
        @scroll="scrolledLetters"
      >
        <button
          v-for="(letter, i) in letters"
          :key="letter"
          :ref="i === letters.length - 1 ? 'lastButton' : undefined"
          class="m-str__letters-btn subhead-3"
          :class="{ active: search === letter }"
          @click="setFirstLetter(letter)"
        >
          {{ letter }}
        </button>
      </div>
      <button
        v-if="leftArrow && disabledPortal"
        class="m-str__letters-scrollbtn m-str__letters-left"
        @click="arrowScroll(false)"
      >
        <img
          loading="lazy"
          src="@/assets/images/useful/arrow_right.svg"
          alt="arrow"
        >
      </button>
      <button
        v-if="rightArrow && disabledPortal"
        class="m-str__letters-scrollbtn m-str__letters-right"
        @click="arrowScroll(true)"
      >
        <img
          loading="lazy"
          src="@/assets/images/useful/arrow_right.svg"
          alt="arrow"
        >
      </button>
    </div>
    <div class="m-str__result">
      <template v-if="loading">
        <div
          v-for="item in 20"
          :key="'load' + item"
          class="m-str__result-item m-str__load d-flex flex-column justify-center"
          style="height: 55px; min-height: 55px; padding-top: 8px"
        >
          <VSkeleton
            style="width: 180px; min-height: 25px; max-height: 25px; max-width: 60%"
          />
          <VSkeleton
            v-if="!(item % 3)"
            style="
              width: 120px;
              min-height: 12px;
              max-height: 12px;
              max-width: 40%;
              margin-top: 4px;
            "
          />
        </div>
      </template>
      <template v-else-if="results">
        <button
          v-for="item in results"
          :key="'street' + item.fias_id"
          class="m-str__result-item title-5"
          @click="selectStreet(item)"
        >
          {{
            `${item.short_name ? item.short_name + " " : ""}${
              item.name
            }`
          }}<span
            v-if="item.parents_tooltip"
            class="d-flex subhead-6 gray-dark--text"
          >{{ item.parents_tooltip }}</span>
        </button>
      </template>
    </div>
    <LazyVDialog
      v-model="dialogCity"
      :fullscreen="!disabledPortal"
      :width="disabledPortal ? 'auto' : ''"
    >
      <LazyModalCityCheck
        @close="dialogCity = false"
        @changed-city="changedCity"
      />
    </LazyVDialog>
  </div>
</template>

<script setup lang="ts">
import { useCities } from '~/store/cites/cities.store'
import { useMainStore } from '~/store/main/main.store'
import { debounce } from '~/helpers'

const ctx = useNuxtApp()
const emit = defineEmits(['close', 'selectStreet'])
const cityStore = useCities()
const mainStore = useMainStore()
const parent = ref()

const currentCity = computed(() => cityStore.getCity)
const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
const disabledPortal = computed(() => mainStore.disabledPortal)
const search = ref('')
const loading = ref(false)
const dialogCity = ref(false)
const leftArrow = ref(false)
const rightArrow = ref(true)
const currentScroll = ref(0)
const scrollRef = ref()
const scrollWrap = ref()
const lastButton = ref()
const results = ref()
const loadingValidate = ref('')
const currentStreet = ref()
const letters = ref([
  'А',
  'Б',
  'В',
  'Г',
  'Д',
  'Е',
  'Ж',
  'З',
  'И',
  'Й',
  'К',
  'Л',
  'М',
  'Н',
  'О',
  'П',
  'Р',
  'С',
  'Т',
  'У',
  'Ф',
  'Х',
  'Ц',
  'Ч',
  'Ш',
  'Щ',
  'Ы',
  'Э',
  'Ю',
  'Я',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
])
const showDialogCity = () => {
  dialogCity.value = true
}
const changedCity = async () => {
  dialogCity.value = false
  loadingValidate.value = Math.random() + ''
  await getSuggestions(loadingValidate.value)
}

const scrolledLetters = (e) => {
  currentScroll.value = e.target.scrollLeft
  leftArrow.value = e.target.scrollLeft > 0
  rightArrow.value
    = lastButton.value?.[0]?.offsetLeft
    > e.target.scrollLeft + scrollWrap.value?.clientWidth
}

const arrowScroll = (val: boolean) => {
  scrollRef.value?.scrollTo({
    left: currentScroll.value + (val ? 200 : -200),
    behavior: 'smooth',
  })
}

const getSuggestions = async (validate) => {
  loading.value = true
  results.value = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/children/`, {
    method: 'GET',
    headers: {
      ...getCommonHeaders.value,
    },
    params: {
      fias_id: currentCity.value?.fias_id,
      query: search.value,
      city_type: 'all',
      sort: !search.value ? 'POPULATION' : 'MATCH',
      level: 'street',
      limit: 200,
      with_available_smart_filters: false,
      with_provider_slugs: false,
      optimized: true,
    },
  })
  if (loadingValidate.value === validate) loading.value = false
}
const searchDebounce = debounce(() => {
  loadingValidate.value = Math.random() + ''
  getSuggestions(loadingValidate.value)
}, 200)

const selectStreet = async (street) => {
  emit('selectStreet', street)
}

const setFirstLetter = (letter: string) => {
  loading.value = true
  if (search.value === letter) search.value = ''
  else search.value = letter
  if (
    currentStreet.value
    && !search.value.includes(currentStreet.value?.name)
  ) {
    currentStreet.value = undefined
  }
  searchDebounce()
}

const setRange = (e: any) => {
  setTimeout(
    () => e.target.setSelectionRange(search.value.length, search.value.length),
    1,
  )
}

const inputSearch = (event: any) => {
  loading.value = true
  event.target.composing = false
  search.value = event.target.value
  if (
    currentStreet.value
    && !search.value.includes(currentStreet.value?.name)
  ) {
    currentStreet.value = undefined
  }
  searchDebounce()
}

const keyDown = (e: any) => {
  if (e.keyCode === 13) e.preventDefault()
}

const clearStreet = (e: any) => {
  search.value = ''
  currentStreet.value = undefined
  loadingValidate.value = Math.random() + ''
  getSuggestions(loadingValidate.value)
}
const scrollTopFn = () => {
  parent.value.scrollTo(0, 0)
  window.removeEventListener('resize', scrollTopFn)
}
onBeforeMount(async () => {
  loading.value = true
  loadingValidate.value = Math.random() + ''
  await getSuggestions(loadingValidate.value)
})
onMounted(() => {
  nextTick(() => parent.value.scrollTo(0, 0))
  if (ctx.$device.isAndroid) {
    window.addEventListener('resize', scrollTopFn)
  }
})

onBeforeUnmount(async () => {
  if (ctx.$device.isAndroid) {
    window.removeEventListener('resize', scrollTopFn)
  }
})
</script>

<style scoped lang="scss">
.m-str {
  max-width: 100vw;
  width: 600px;
  padding: 16px 40px 16px;
  max-height: 100vh;
  height: 561px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 16px;
  @media (max-width: getBreakpoint(tablet)) {
    max-height: unset;
    height: 100vh;
    //height: var(--app-height);
    padding: 8px 16px 0;
    border-radius: 0;
  }

  &__header {
    margin-bottom: 16px;
  }

  &__city {
    margin-left: 28px;
    color: color(secondary-dark);
    transition: color 0.3s;

    &:hover {
      color: color(primary);
    }
  }

  &__search {
    position: relative;
    margin-bottom: 16px;
    &-search {
      display: flex;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      left: 18px;
    }
    &-clear {
      right: 16px;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 28px;
      height: 28px;
      @media (max-width: getBreakpoint(tablet)) {
        right: 10px;
      }
    }
    input {
      width: 100%;
      background: color(gray-p);
      border: 1px solid color(gray-darker);
      border-radius: 28px;
      transition: border 0.24s;
      padding: 16px 48px;
      &:focus {
        border: 1px solid color(secondary-dark);
      }
      @media (max-width: getBreakpoint(tablet)) {
        height: 40px;
      }
    }
  }

  &__letters {
    margin-bottom: 4px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-left: -16px;
      margin-right: -16px;
    }
    &:deep(.d-flex) {
      overflow-x: auto;
      @media (max-width: getBreakpoint(tablet)) {
        padding-left: 16px;
        padding-right: 16px;
      }
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar-track {
        background: color(white);
        border-radius: 4px;
        transition: background .24s;
      }
      &::-webkit-scrollbar-thumb {
        background-color: color(white);
        transition: background-color .24s;
      }
      &:hover {
        &::-webkit-scrollbar-track {
          background: color(gray-light);
        }
        &::-webkit-scrollbar-thumb {
          background-color: color(gray-2);
        }
      }
    }
    &-btn {
      padding: 12px 20px;
      border-radius: 28px;
      background-color: color(gray-p);
      margin-right: 8px;
      transition: background-color 0.24s;
      box-sizing: border-box;
      border: 1px solid color(gray-light-3);

      @media (max-width: getBreakpoint(tablet)) {
      }
      &.active {
        border: 1px solid color(primary);
      }
      &:hover {
        background-color: color(gray-light-3);
      }
    }

    &-scrollbtn {
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: color(white);
      box-shadow: 0px 0px 5px 0px rgba(2, 4, 30, 0.15);
      top: calc(50% - 6px);
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 24px;
        height: 24px;
      }
    }
    &-right {
      right: -10px;
    }
    &-left {
      left: -10px;
      img {
        transform: rotate(180deg);
      }
    }
  }

  &__result {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow-y: auto;
    overflow-x: hidden;
    @media (max-width: getBreakpoint(tablet)) {
      grid-template-columns: repeat(1, 1fr);
    }
    &-houses {
      overflow-y: auto;
    }
    &-item {
      min-height: 55px;
      height: 55px;
      padding: 12px 12px 11px 0;
      border-bottom: 1px solid color(gray-light-3);
      overflow: hidden;
      color: color(black);
      transition: color 0.24s;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      &:hover {
        color: color(primary);
      }
      span {
        margin-bottom: -8px;
      }
    }
    &-house {
      padding-left: 32px;
      position: relative;
    }
    &-icon {
      position: absolute;
      left: 0;
    }
  }

  &__load {
  }
}
</style>
